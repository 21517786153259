import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { UserContext } from '/src/contexts/UserContext'

import {
    EmailShareButton,
    FacebookShareButton,
    FacebookMessengerShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon
} from 'react-share'
import './styles.scss'

const ShareIcons = ({ referUrl }) => {
    const [user] = useContext(UserContext)

    const shareTitle = `${user.name} referred you for Life Insurance with Protect Line`

    const emailMessage = `Hi,
I've protected my loved ones by getting life insurance and I couldn't be happier knowing that they will be secure should the worst happen...
With Protect Line the whole process was super easy and the staff were so helpful and friendly. It's no wonder they're the UK's #1 rated Life Insurance Broker on Trustpilot.
What's more the whole process didn't have any fees and I received the quotes for free!
If this sounds good to you and you'd like to protect your loved ones and get the same peace of mind, follow this link: `

    return (
        <div className="c-share-icons">
            <div className="c-share-code-header__label">Or share via:</div>

            <div className="c-share-icons__inner">
                <EmailShareButton
                    children={<EmailIcon size={60} round={true} />}
                    url={referUrl}
                    subject={shareTitle}
                    body={emailMessage}
                />

                <FacebookShareButton
                    children={<FacebookIcon size={60} round={true} />}
                    url={referUrl}
                    quote={shareTitle}
                />

                <TwitterShareButton
                    children={<TwitterIcon size={60} round={true} />}
                    url={referUrl}
                    title={shareTitle}
                />

                <FacebookMessengerShareButton
                    children={<FacebookMessengerIcon size={60} round={true} />}
                    url={referUrl}
                    appId={'1234'}
                />

                <WhatsappShareButton
                    children={<WhatsappIcon size={60} round={true} />}
                    url={referUrl}
                    title={shareTitle}
                />

                <TelegramShareButton
                    children={<TelegramIcon size={60} round={true} />}
                    url={referUrl}
                    title={shareTitle}
                />
            </div>
        </div>
    )
}

ShareIcons.propTypes = {
    /**
     * URL to use as the share link
     */
    referUrl: PropTypes.string.isRequired
}

ShareIcons.defaultProps = {
    referUrl: 'http://www.protectline.co.uk'
}

export default ShareIcons
